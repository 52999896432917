/**
 * @author Tran Trung
 * @copyright Kyanon Digital
 */
import { SINGLE_API } from "./type";
import queryString from "query-string";

export const getListGroupAssignmentRequest = (payload, next, nextError) => {
  const { organizationId, orgUserId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group/assigned_groups?org_user_id=${orgUserId}`,
      beforeCallType: "GET_LIST_GROUP_ASSIGNMENT_REQUEST",
      successType: "GET_LIST_GROUP_ASSIGNMENT_SUCCESS",
      afterSuccess: next,
      afterError: nextError,
    },
  };
};

export const getInfoGroupAssignmentRequest = (payload, next, nextError) => {
  const { organizationId, orgGroupId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group/${orgGroupId}`,
      beforeCallType: "GET_INFO_GROUP_ASSIGNMENT_REQUEST",
      successType: "GET_INFO_GROUP_ASSIGNMENT_SUCCESS",
      afterSuccess: next,
      afterError: nextError,
    },
  };
};

export const updateAssignmentRequest = (payload, next, nextErr) => {
  const { organizationId, orgUserIds, orgGroupId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group/assign_users`,
      beforeCallType: "UPDATE_GROUP_ASSIGNMENT_REQUEST",
      successType: "UPDATE_GROUP_ASSIGNMENT_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: {
        org_user_ids: orgUserIds,
        org_group_id: orgGroupId,
      },
      opt: {
        method: "PUT",
      },
    },
  };
};

export const getListGroupRequest = (payload, next, nextError) => {
  const { organizationId, search_name, page, item_per_page } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/groups?organization_id=${organizationId}&search_name=${search_name}&page=${page}&item_per_page=${item_per_page}`,
      beforeCallType: "GET_LIST_GROUP_REQUEST",
      successType: "GET_LIST_GROUP_SUCCESS",
      afterSuccess: next,
      afterError: nextError,
    },
  };
};

export const updatePermissionGroup = (payload, next, nextErr) => {
  const { organizationId, permissions, orgGroupId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group/${orgGroupId}/update`,
      beforeCallType: "UPDATE_PERMISSION_GROUP_REQUEST",
      successType: "UPDATE_PERMISSION_GROUP_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: {
        permissions,
      },
      opt: {
        method: "PUT",
      },
    },
  };
};

export const updateNameGroup = (payload, next, nextErr) => {
  const { organizationId, new_group_name, orgGroupId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group/${orgGroupId}/update`,
      beforeCallType: "UPDATE_NAME_GROUP_REQUEST",
      successType: "UPDATE_NAME_GROUP_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: {
        new_group_name,
      },
      opt: {
        method: "PUT",
      },
    },
  };
};

export const updateBuildingGroup = (payload, next, nextErr) => {
  const { organizationId, buildings, orgGroupId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group/${orgGroupId}/update`,
      beforeCallType: "UPDATE_BUILDING_GROUP_REQUEST",
      successType: "UPDATE_BUILDING_GROUP_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: {
        buildings,
      },
      opt: {
        method: "PUT",
      },
    },
  };
};

export const updateStaffGroup = (payload, next, nextErr) => {
  const { organizationId, staffs, orgGroupId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group/${orgGroupId}/update`,
      beforeCallType: "UPDATE_STAFF_GROUP_REQUEST",
      successType: "UPDATE_STAFF_GROUP_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: {
        staffs,
      },
      opt: {
        method: "PUT",
      },
    },
  };
};

/**
 * @deprecated
 */
export const getTemplatePermissionGroup = (payload, next, nextErr) => {
  const { organization_id } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organization_id}/templates`,
      beforeCallType: "GET_TEMPLATE_REQUEST",
      successType: "GET_TEMPLATE_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
    },
  };
};

export const createGroup = (payload, next, nextErr) => {
  const {
    organizationId,
    name,
    staffs,
    buildings,
    // forContractor,
    companyName,
    companyPhone,
    contractorType,
    groupType,
    permissions,
  } = payload;

  if (groupType === "Contractor") {
  }

  const params =
    groupType === "Contractor"
      ? {
          company_name: companyName,
          phone_number: companyPhone,
          contractor_type: contractorType,
        }
      : {};

  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group`,
      beforeCallType: "CREATE_GROUP_REQUEST",
      successType: "CREATE_GROUP_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: {
        name,
        staffs,
        buildings,
        // is_contractor: forContractor,
        client_group_type: groupType,
        permissions,
        ...params,
      },
      opt: {
        method: "POST",
      },
    },
  };
};

export const removeGroup = (payload, next, nextErr) => {
  const { organizationId, groupId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group/${groupId}/delete`,
      beforeCallType: "DELETE_GROUP_REQUEST",
      successType: "DELETE_GROUP_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: {},
      opt: {
        method: "DELETE",
      },
    },
  };
};

export const createGroupScheduleProject = (payload, next, nextErr) => {
  const { organizationId, body } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group-schedules`,
      beforeCallType: "CREATE_GROUP_SCHEDULE_PROJECT_REQUEST",
      successType: "CREATE_GROUP_SCHEDULE_PROJECT_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: body,
      opt: {
        method: "POST",
      },
    },
  };
};

export const getListGroupScheduleProject = (payload, next, nextErr) => {
  const { organizationId, params } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group-schedules?${queryString.stringify(
        params
      )}`,
      beforeCallType: "GET_LIST_GROUP_SCHEDULE_PROJECT_REQUEST",
      successType: "GET_LIST_GROUP_SCHEDULE_PROJECT_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: "GET",
      },
    },
  };
};

export const updateGroupScheduleProject = (payload, next, nextErr) => {
  const { organizationId, groupId, body } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group-schedules/${groupId}`,
      beforeCallType: "UPDATE_GROUP_SCHEDULE_PROJECT_REQUEST",
      successType: "UPDATE_GROUP_SCHEDULE_PROJECT_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      params: body,
      opt: {
        method: "PUT",
      },
    },
  };
};

export const deleteGroupScheduleProject = (payload, next, nextErr) => {
  const { organizationId, groupId } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/group-schedules/${groupId}`,
      beforeCallType: "DELETE_GROUP_SCHEDULE_PROJECT_REQUEST",
      successType: "DELETE_GROUP_SCHEDULE_PROJECT_SUCCESS",
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: "DELETE",
      },
    },
  };
};