//Import css files
// import "styles/style.scss";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/style.scss";


import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Wrapper from "layout/Wrapper";
import ReactGA from "react-ga";
import env from "config/env";
const REACT_APP_GA = env.REACT_APP_GA;
ReactGA.initialize(REACT_APP_GA);
export default function App() {
  return (
    <Router>
      <Wrapper />
    </Router>
  );
}
