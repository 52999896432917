let env = {
  REACT_APP_URL_API: process.env.REACT_APP_URL_API,
  REACT_APP_URL_BACKEND: process.env.REACT_APP_URL_BACKEND,
  REACT_APP_URL_STRIPE: process.env.REACT_APP_URL_STRIPE,
  REACT_APP_URL_API_COMMON: process.env.REACT_APP_URL_API_COMMON,
  REACT_APP_SSL: process.env.REACT_APP_SSL,
  REACT_APP_GA: process.env.REACT_APP_GA,
  REACT_APP_AUTODESK_CLIENT_ID: process.env.REACT_APP_AUTODESK_CLIENT_ID,
  REACT_APP_AUTODESK_APP_NAME: process.env.REACT_APP_AUTODESK_APP_NAME,
  REACT_APP_AUTODESK_REDIRECT_URL: encodeURI(process.env.REACT_APP_AUTODESK_REDIRECT_URL),
  REACT_APP_AUTODESK_COOKIE_KEY: process.env.REACT_APP_AUTODESK_COOKIE_KEY,
  REACT_APP_AUTODESK_API_URL:
    process.env.REACT_APP_AUTODESK_URL || "https://developer.api.autodesk.com",
  REACT_APP_GOOGLE_MAP_KEY: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyDDr1qnwgNalkR7MWqCkDPcqUuBlLaMhpU',
  REACT_APP_HEADER_BACKGROUND_COLOR: process.env.REACT_APP_HEADER_BACKGROUND_COLOR
};

if (process.env.NODE_ENV === "development") {
  env = {
    ...env,
    // REACT_APP_URL_API: 'http://10.20.102.211:8000/api/v1/',
    // REACT_APP_URL_BACKEND: '10.20.102.211:8000',
    // REACT_APP_URL_STRIPE: 'http://10.20.102.211:8000/api/',
    // REACT_APP_URL_API_COMMON: 'http://ec2-13-250-5-200.ap-southeast-1.compute.amazonaws.com:8000/',
    // REACT_APP_SSL: 'wss',
    // REACT_APP_GA: 'UA-164219656-1',
    // REACT_APP_AUTODESK_CLIENT_ID: "qpaWqgFSTBp3s3gkpYQXWSiB8mPWtPBn",
    // REACT_APP_AUTODESK_APP_NAME: "FacadeInspector",
    // REACT_APP_AUTODESK_REDIRECT_URL: "http%3A%2F%2Flocalhost%3A3000%2Fautodesk",
    // REACT_APP_AUTODESK_COOKIE_KEY: "autodesk-123123",
    //http%3A%2F%2Flocalhost%3A3000%2Finspection%2F803
  };
}

export default env;
