/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @author Tran Trung
 * @description refactor code and implement redux
 * @copyright Kyanon Digital
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import LoadingBar from "react-redux-loading-bar";
import { css } from "emotion";
// import { get } from "lodash";

//compoent
import NavBarMobile from "../NavBarMobile";
import ToolBarLang from "../ToolBar/Lang";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AuthStorage from "utils/AuthStorage";
import { logoutRequest } from "redux/action/auth";

import LangStorage from "utils/LangStorage";

import { AppContext } from "layout/AppContext";
import UnsubscribeNotice from "components/UnsubscribeNotice";

// import { defaultConfig } from "utils/Constants";
function mapStateToProps(state) {
  // const contractorPermission =  get(state, 'user.contractorPermission.data', {})
  return {
    store: {
      roleUser: state.user.roleUser.data || {},
      auth: state.auth,
      languageContent: state.languageContent.languageContent.data,
      language: state.languageContent.languageList.data,
      // canAccessProject: Object.values(contractorPermission).some(item => item === true)
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        logoutRequest,
      },
      dispatch
    ),
  };
};

class Navbar extends Component {
  // contextType = AppContext;

  state = {
    isShow: false,
    isToken: false,
    headerLine: "",
    visibleNotice: true,
    activeLanguage: "",
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.setState({ isToken: true });
    }

    if (AuthStorage.loggedIn) {
      this.setState({ isToken: true });
    }
    this.handleUpdateCanvas();
    window.addEventListener("resize", this.handleUpdateCanvas);
    this.setState({ activeLanguage: LangStorage.value?.language });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleUpdateCanvas);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.store.auth !== this.props.store.auth) {
      //Perform some operation
      if (AuthStorage.loggedIn) {
        this.setState({ isToken: true });
      } else {
        this.setState({ isToken: false });
        this.props.history.push("/signin");
      }
    }
  }

  toggleMenu = () => {
    const { isShow } = this.state;
    this.setState({ isShow: !isShow });
  };

  changeLanguage = (code) => {
    LangStorage.value = {
      language: code,
    };
    this.setState({ activeLanguage: code });
    this.props.handleGetLanguageContent(code);
  };

  handleUpdateCanvas = () => {
    if (window.innerWidth > 1279) {
      const headerLine = css({
        "@media screen and (min-width: 1280px)": {
          "&:after": {
            content: '""',
            backgroundImage: `url(${this.drawHeader()})`,
            position: "absolute",
            width: "100%",
            height: "100%",
          },
        },
      });
      this.setState({ headerLine });
    }
  };

  handleLogout = () => {
    this.props.action.logoutRequest();
  };

  drawHeader = () => {
    const { appContext } = this.context;
    const { config } = appContext.state;
    let canvas, context;
    const elem = document.getElementById("header-top");
    const logo = document.getElementById("header-logo");
    canvas = document.createElement("canvas");
    canvas.width = elem?.offsetWidth ?? 1920;
    canvas.height = elem?.offsetHeight ?? 98;
    context = canvas.getContext("2d");

    const pointA = {
      x: (250 * -2 + 96 * ((logo?.offsetWidth ?? 640) - 250)) / 96,
      y: -2,
    };
    const pointB = {
      x: logo?.offsetWidth ?? 640,
      y: (logo?.offsetHeight ?? 96) - 1,
    };
    const pointC = {
      x: elem?.offsetWidth ?? 1920,
      y: (elem?.offsetHeight ?? 96) - 1,
    };
    context.lineWidth = config.baseConfig.lineHeaderWidth;
    context.beginPath();
    context.moveTo(pointA.x, pointA.y);
    context.lineTo(pointB.x, pointB.y);
    context.lineTo(pointC.x, pointC.y);
    context.strokeStyle = config.baseConfig.lineHeaderColor;
    context.stroke();

    return canvas.toDataURL();
  };

  handleCloseNotice = () => {
    this.setState({ visibleNotice: false });
  };

  render() {
    const { languageContent, language, roleUser = {} } = this.props.store;
    const { appContext } = this.context;
    const { config } = appContext.state;
    const { headerLine } = this.state;

    return (
      <>
        <LoadingBar
          className="process-bar"
          style={{ backgroundColor: "#60cdf6", height: "3px" }}
        />
        <div className="header-notice">
          <UnsubscribeNotice
            visible={this.state.visibleNotice}
            onClose={this.handleCloseNotice}
          />
        </div>
        <div className="header-top container-fluid" id="header-top">
          <div className="row header-notice--mobile">
            <div className="w-100">
              <UnsubscribeNotice
                visible={this.state.visibleNotice}
                onClose={this.handleCloseNotice}
              />
            </div>
          </div>
          <div
            className={`row ${headerLine}`}
            // style={{ backgroundImage: `url(${config.headerLine})` }}
          >
            <div
              className={`col col-md-4 logo ${config.backgroundRoot}`}
              id="header-logo"
            >
              <Link to="/">
                <img src={config.baseConfig.logoClient} alt="Logo" />
              </Link>
            </div>
            <div className="col col-md-8 navbar-desktop">
              <nav className="navbar navbar-expand-lg">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleMenu}
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  className={`collapse navbar-collapse ${
                    this.state.isShow ? "show" : ""
                  }`}
                  id="navbarResponsive"
                >
                  <ul className="nav navbar-nav ml-auto">
                    {this.state.isToken ? (
                      <>
                        <li className="menu-item nav-item">
                          <Link
                            to="/dashboard"
                            className={`nav-link ${config.linkRoot}`}
                          >
                            {languageContent.data.dashboardPage.titlePage}
                          </Link>
                        </li>
                        {!!roleUser?.view_inspection && (
                          <li className="menu-item nav-item">
                            <Link
                              to="/inspections"
                              onClick={this.toggleMenu}
                              className={`nav-link ${config.linkRoot}`}
                            >
                              {languageContent.data &&
                                languageContent.data.missionPage.titlePage}
                            </Link>
                          </li>
                        )}
                        {(!!roleUser?.view_project || !!roleUser?.is_admin) &&
                          !AuthStorage.isSuperAdmin && (
                            <li className="menu-item nav-item">
                              <Link
                                to="/projects"
                                onClick={this.toggleMenu}
                                className={`nav-link ${config.linkRoot}`}
                              >
                                {languageContent.data?.projectPage?.titlePage ||
                                  "Projects"}
                              </Link>
                            </li>
                          )}
                        {!!roleUser?.view_building && (
                          <li className="menu-item nav-item">
                            <Link
                              to="/buildings"
                              onClick={this.toggleMenu}
                              className={`nav-link ${config.linkRoot}`}
                            >
                              {languageContent.data.buildingPage.titlePage}
                            </Link>
                          </li>
                        )}
                        {(!!roleUser?.is_admin ||
                          !!AuthStorage.isSuperAdmin) && (
                          <li className="menu-item nav-item">
                            <Link
                              to="/management"
                              onClick={this.toggleMenu}
                              className={`nav-link ${config.linkRoot}`}
                            >
                              {
                                languageContent.data.administrationPage
                                  .titlePage
                              }
                            </Link>
                          </li>
                        )}

                        <li className="menu-item nav-item has-submenu">
                          <div className="header__user--wrap">
                            <span className="header__user--text">
                              {AuthStorage.username}
                            </span>
                            <i
                              className="fa fa-2x fa-user-circle"
                              aria-hidden="true"
                            />
                          </div>
                          <ul className="navbar_submenu">
                            <li className="menu-item nav-item">
                              <Link
                                to="/profile"
                                className={`nav-link ${config.linkRoot}`}
                              >
                                {languageContent.data.profilePage.titlePage}
                              </Link>
                            </li>
                            <li className="menu-item nav-item">
                              <Link
                                onClick={this.handleLogout}
                                to="/signin"
                                className={`nav-link ${config.linkRoot}`}
                              >
                                {languageContent.data.textLogout}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li className="menu-item nav-item is-mobile">
                          <Link
                            to="/profile"
                            className={`nav-link ${config.linkRoot}`}
                          >
                            {languageContent.data.profilePage.titlePage}
                          </Link>
                        </li>
                        <li className="menu-item nav-item is-mobile">
                          <Link
                            onClick={this.handleLogout}
                            to="/signin"
                            className={`nav-link ${config.linkRoot}`}
                          >
                            {languageContent.data.textLogout}
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li className="menu-item" style={{ lineHeight: "40px" }}>
                        <Link
                          to="/signin"
                          className={`text-uppercase ${config.linkRoot}`}
                        >
                          {languageContent.data.loginPage.titlePage}
                        </Link>
                      </li>
                    )}
                    <li className="menu-item nav-item has-submenu">
                      <div className="header__lang--wrap">
                        <i
                          className="fa fa-2x fa-globe header__lang--icon"
                          aria-hidden="true"
                        />
                      </div>
                      <ul
                        className="navbar_submenu"
                        style={{ width: "100%", right: "15px" }}
                      >
                        {language.data.length > 0 &&
                          language.data.map((item, index) => {
                            return (
                              <li
                                key={index}
                                className={`${
                                  config.linkRoot
                                } menu-item nav-item ${
                                  this.state.activeLanguage === item.code
                                    ? "active"
                                    : ""
                                }`}
                              >
                                <a
                                  style={{ padding: "10px" }}
                                  onClick={() => {
                                    this.changeLanguage(item.code);
                                  }}
                                  className={`nav-link ${config.linkRoot} text-uppercase `}
                                >
                                  {item.name}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className={`col navbar-mobile ${config.backgroundRoot}`}>
              <div className="kt-header-mobile__toolbar">
                {this.state.isToken && (
                  <div className="kt-header__topbar-wrapper">
                    <NavBarMobile
                      handleGetLanguageContent={
                        this.props.handleGetLanguageContent
                      }
                      isToken={this.state.isToken}
                      handleLogout={this.handleLogout}
                    />
                  </div>
                )}

                {language.data.length > 0 && (
                  <ToolBarLang
                    data={language.data}
                    changeLanguage={this.changeLanguage}
                    activeLanguage={this.state.activeLanguage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
Navbar.contextType = AppContext;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
